<template>
  <router-view />
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "HomeView",
  created() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapMutations(["setMobileVersion"]),
    onResize() {
      this.setMobileVersion(window.innerWidth <= 760);
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: "Proxima Nova";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e2228;
  background: #f3f5f7;
  min-height: 100%;
  @media (max-width: 760px) {
    background: #fff;
  }
}
.error-mode {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.error-header {
  text-align: center;
  font-weight: 800;
  font-size: 2.5rem;
  opacity: 0.3;
  margin: 1rem;
}

.error-description {
  text-align: center;
  margin: 0 1rem;
}

.body-loading {
  position: relative;
  min-height: 100vh;
}

.body-loading::after {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  background-image: url("~@/assets/images/icons/loader-small-red.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.selected {
  fill: #b9dbff !important;
}
</style>
