import { Seat } from "./../grpc/code-gen/seat_pb.d";
import store from "@/store";
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    allSeats: [] as Seat.AsObject[],
    selectedSeat: {} as Seat.AsObject,
    currentSeats: [] as Seat.AsObject[]
  },
  actions: {
    async getAllSeatsList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.seat
          .GetAllSeatsList()
          .then(response => {
            if (response) {
              s.commit("addAllSeatsList", response.seatList);
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    async getByCoworkingSeatsList(s: any, coworkingId: string): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.seat
          .SelCoworkingIdSeats(coworkingId)
          .then(response => {
            if (response) {
              s.commit("addCurrentSeats", response.seatList);
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    addSeat(state: any, payload: Seat.AsObject): void {
      infra.seat
        .AddSeat(payload)
        .then(response => {
          console.log("add seat success ", response);
          state.dispatch("getAllSeatsList");
        })
        .catch(error => {
          console.log("add seat failed", error);
          store.commit("setNetworkError");
        });
    },
    updSeat(state: any, payload: Seat.AsObject): void {
      infra.seat
        .UpdSeat(payload)
        .then(response => {
          console.log("upd seat success  ", response);
          state.dispatch("getAllSeatsList");
        })
        .catch(error => {
          console.log("upd seat failed", error);
          store.commit("setNetworkError");
        });
    },
    delSeat(state: any, payload: Seat.AsObject): void {
      infra.seat
        .DelSeat(payload)
        .then(response => {
          console.log("del seat success  ", response);
          state.dispatch("getAllSeatsList");
        })
        .catch(error => {
          console.log("del seat failed", error);
          store.commit("setNetworkError");
        });
    }
  } as any,
  mutations: {
    addAllSeatsList(state: any, arr) {
      state.allSeats = arr;
    },
    addCurrentSeats(state: any, arr) {
      state.currentSeats = arr;
    },
    addCurrentSeat(state: any, seat: Seat) {
      state.currentSeats.push(seat);
    },
    setSeat(state: any, seat: object) {
      state.selectedSeat = seat;
    }
  },
  getters: {
    getAllSeats(state: any) {
      return state.allSeats;
    },
    getCurrentSeats(state: any) {
      return state.currentSeats;
    },
    getAvailableCurrentSeats(state: any) {
      return state.currentSeats.filter(s => s.islocked && s.isavailable);
    },
    getSeatById: (state: any) => (id: string) => {
      const obj = state.allSeats.filter(seat => {
        return seat.id === id;
      })[0];
      return obj;
    },
    selectedSeat: (s: any) => s.selectedSeat
  }
};
