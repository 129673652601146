import { Chat } from "./../grpc/code-gen/telegram_pb.d";
import Infrastructure from "@/infrastructure";
const infra = new Infrastructure();
export default {
  namespaced: true,
  state: {
    chats: [] as Chat.AsObject[]
  },
  actions: {
    async loadChatsList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.chat
          .GetChatsList()
          .then(response => {
            if (response) {
              s.commit("addChatsList", response.chatList);
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject();
          });
      });
    }
  },
  mutations: {
    addChatsList(s: any, arr: Chat.AsObject[]): void {
      s.chats = arr;
    }
  },
  getters: {
    getChats: (s: any) => s.chats,
    getChatById: (state: any) => (id: string) => {
      const obj = state.chats.filter(chat => {
        return chat.id === id;
      })[0];
      return obj;
    }
  }
};
