import IParticipant from "@/models/participant";
import { userRolesJWT } from "@/common/common";
import Infrastructure from "@/infrastructure";
import { UsersResponse } from "@/grpc/code-gen/users_pb";
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    userRoles: [userRolesJWT.REGULAR],
    user: {} as any,
    usersList: [] as any
  },
  actions: {
    checkUserRole(state: any, payload: { code: string; role: string }): void {
      infra.user
        .CheckUserRole(payload.code, payload.role)
        .then(response => {
          if (response.result) {
            if (payload.role.includes("admin"))
              state.commit("setUserRole", userRolesJWT.ADMIN);
            if (payload.role.includes("security"))
              state.commit("setUserRole", userRolesJWT.SECURITY);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    getUsersList(state: any): void {
      infra.user
        .GetUsersList()
        .then(response => {
          state.commit("setUsersList", response.usersList);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  },
  mutations: {
    setUserPortalCode(state: any, payload: string) {
      state.user.portalCode = payload;
    },
    setUserFirstName(state: any, payload: string) {
      state.user.firstName = payload;
    },
    setUserLastName(state: any, payload: string) {
      state.user.lastName = payload;
    },
    setUserRole(state: any, payload: userRolesJWT) {
      state.userRoles.push(payload);
    },
    setUsersList(state: any, payload: UsersResponse.AsObject) {
      state.usersList = payload;
    }
  },
  getters: {
    user: (s: any) => s.user,
    isUserAdmin: (s: any) => s.userRoles.includes(userRolesJWT.ADMIN),
    isUserSec: (s: any) =>
      s.userRoles.includes(userRolesJWT.ADMIN) ||
      s.userRoles.includes(userRolesJWT.SECURITY)
  }
};
