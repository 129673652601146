import store from "@/store";
import Infrastructure from "@/infrastructure";
const infra = new Infrastructure();
import Router from "../router";

export default {
  namespaced: true,
  state: {
    equipments: []
  },
  actions: {
    loadEquipmentsList(s: any): Promise<void> {
      return new Promise((resolve, reject) => {
        infra.equipment
          .GetEquipmentsList()
          .then(response => {
            if (response) {
              if (response.equipmentList.length) {
                s.commit("addEquipmentsList", response.equipmentList);
              }
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject();
          });
      });
    }
  },
  mutations: {
    addEquipmentsList(s: any, arr): void {
      s.equipments = arr;
    }
  },
  getters: {
    getEquipments: (s: any) => s.equipments
  }
};
