import store from "@/store";
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
import Router from "../router";
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    maps: new Array()
  },
  actions: {
    addMap(
      state: any,
      payload: {
        id: string;
        building: string;
        floor: string;
        map: string;
        type?: string;
      }
    ) {
      infra.map
        .AddMap(payload)
        .then(response => {
          console.log("карта добавлена");
        })
        .catch(error => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    updMap(
      state: any,
      payload: {
        id: string;
        building: string;
        floor: string;
        map: string;
        type?: string;
      }
    ) {
      infra.map
        .UpdMap(payload)
        .then(response => {
          console.log("карта обновлена");
          state.commit("setUpdMap", payload);
        })
        .catch(error => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    delMap(
      state: any,
      payload: {
        id: string;
        building: string;
        floor: string;
        map: string;
        type?: string;
      }
    ) {
      infra.map
        .DelMap(payload)
        .then(response => {
          console.log("карта удалена");
        })
        .catch(error => {
          console.log(error);
          this.$store.commit("setNetworkError");
        });
    },
    selMapForCache(
      state: any,
      payload: { building: string; floor: string; type?: string }
    ) {
      infra.map
        .SelMap(payload.building, payload.floor, payload?.type)
        .then(response => {
          if (response.mapList.length) {
            state.commit("setMaps", response.mapList[0]);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.commit("setNetworkError");
        });
    }
  } as any,
  mutations: {
    setMaps(state: any, payload: any) {
      state.maps.push(payload);
    },
    setUpdMap(state: any, payload: any) {
      state.maps = state.maps.map(m => {
        if (m.id === payload.id) {
          return payload;
        } else {
          return m;
        }
      });
    },
    clearMapCache(state: any, payload: any) {
      state.maps = [];
    }
  },
  getters: {
    getMaps: (s: any) => s.maps
  }
};
