export function GrpcConfig(): {
  url: string;
  socket: string;
  metadata: { Authorization: string };
} {
  return {
    url: window.VUE_APP_API_URL,
    socket: window.VUE_APP_API_SOCKET,
    metadata: {
      Authorization: `bearer ${localStorage.getItem("booking_access_token")}`
    }
  };
}
