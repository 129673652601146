import LifeBridge from "@tnlife/tn-bridge";

export default class Bridge {
  public send(type: string, data): Promise<{ type: string; data }> {
    return LifeBridge.send(type, data);
  }
  static send(type: string, data): Promise<{ type: string; data }> {
    return LifeBridge.send(type, data);
  }
  public isMiniApp(): boolean {
    return LifeBridge.isWebView || LifeBridge.isIFrame;
  }
  static isMiniApp(): boolean {
    return LifeBridge.isWebView || LifeBridge.isIFrame;
  }
}
