import { Building } from "./../grpc/code-gen/building_pb.d";
import store from "@/store";
/* eslint-disable no-console */
import IBuilding from "@/models/building";
import { v4 as uuidv4 } from "uuid";
import Infrastructure from "@/infrastructure";
import Router from "../router";
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    buildings: [] as Array<Building.AsObject>,
    allBuildings: [] as Array<Building.AsObject>,
    emptyBuilding: {
      id: "",
      name: "Не найдено",
      city: "",
      floorsList: [],
      img: "",
      coworkingtotalseats: ""
    } as Building.AsObject
  } as any,
  actions: {
    async loadBuildingsList(s: any, city: string): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.location
          .GetBuildingsList(city)
          .then(response => {
            if (response) {
              s.commit("addBuildingsList", response.buildingList);
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject();
          });
      });
    },
    async loadAllBuildingsList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.location
          .GetAllBuildingsList()
          .then(response => {
            if (response) {
              s.commit("addAllBuildingsList", response.buildingList);
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject();
          });
      });
    },
    addBuilding(state: any, payload: Building.AsObject): void {
      infra.location
        .AddBuilding(payload)
        .then(response => {
          console.log("add building success  ", response);
          state.dispatch("loadAllBuildingsList");
        })
        .catch(error => {
          console.log("add building failed", error);
          store.commit("setNetworkError");
        });
    },
    updBuilding(state: any, payload: Building.AsObject): void {
      infra.location
        .UpdBuilding(payload)
        .then(response => {
          console.log("upd building success  ", response);
          state.dispatch("loadAllBuildingsList");
        })
        .catch(error => {
          console.log("upd building failed", error);
          store.commit("setNetworkError");
        });
    },
    delBuilding(state: any, payload: Building.AsObject): void {
      infra.location
        .DelBuilding(payload)
        .then(response => {
          console.log("del building success  ", response);
          state.dispatch("loadBuildingsList");
        })
        .catch(error => {
          console.log("del building failed", error);
          store.commit("setNetworkError");
        });
    }
  } as any,
  mutations: {
    addBuildingsList(state: any, payload: any) {
      state.buildings = payload;
    },
    addAllBuildingsList(state: any, payload: any) {
      state.allBuildings = payload;
    },
    editCityInBuildings(state: any, payload: any) {
      state.buildings = state.buildings.map(b =>
        b.city === payload.city ? { ...b, city: payload.newCityName } : b
      );
    },
    addBuilding(state: any, payload: any) {
      state.buildings.push({
        id: uuidv4(),
        name: payload.name,
        city: payload.city,
        floors: []
      });
    },
    editBuildingName(state: any, payload: any) {
      state.buildings.find(b => b.id === payload.id).name =
        payload.newBuildingName;
    },
    addFloor(state: any, payload: any) {
      state.buildings
        .find(b => b.id === payload.id)
        .floors.push(payload.newFloor);
    },
    editBuildingFloor(state: any, payload: any) {
      const floorsInBuilding = state.buildings.find(
        b => b.id === payload.building
      ).floors;
      const editableFloorIndex = floorsInBuilding.indexOf(payload.floor);
      floorsInBuilding.splice(editableFloorIndex, 1, payload.newFloor);
    }
  },
  getters: {
    getBuildings: (s: any) => s.buildings,
    getAllBuildings: (s: any) => s.allBuildings,
    getBuildingById: (state: any) => (id: string) => {
      const obj = state.allBuildings.filter(building => {
        return building.id === id;
      })[0];
      return obj || state.emptyBuilding;
    }
    // getBuildingsByCity: (s: any) => (city: string) => {
    //   return s.buildings.filter(b => b.city === city);
    // }
  }
};
