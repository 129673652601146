import { RouteRecordRaw } from "vue-router";
import store from "@/store";

export const backofficeRoutes: Array<RouteRecordRaw> = [
  {
    path: "backoffice",
    name: "backoffice",
    component: () =>
      import("@/views/backoffice/backoffice-view/backoffice-view.vue"),
    redirect: { name: "backoffice-rooms" },
    children: [
      {
        path: "rooms",
        name: "backoffice-rooms",
        component: () =>
          import("@/views/backoffice/backoffice-rooms/backoffice-rooms.vue")
      },
      {
        path: "coworking",
        name: "backoffice-coworking",
        component: () =>
          import(
            "@/views/backoffice/backoffice-coworking/backoffice-coworking-page/backoffice-coworking-page.vue"
          ),
        redirect: { name: "backoffice-coworking-dashboard" },
        children: [
          {
            path: "dashboard",
            name: "backoffice-coworking-dashboard",
            component: () =>
              import(
                "@/views/backoffice/backoffice-coworking/backoffice-coworking-dashboard/backoffice-coworking-dashboard.vue"
              )
          },
          {
            path: "view",
            name: "backoffice-coworking-view",
            component: () =>
              import(
                "@/views/backoffice/backoffice-coworking/backoffice-coworking-view/backoffice-coworking-view.vue"
              )
          }
        ]
      }
    ],
    meta: {
      forMobile: false
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isUserAdmin"]) next();
      else next(false);
    }
  },
  {
    path: "backoffice-sec",
    name: "backoffice-sec",
    component: () =>
      import(
        "@/views/backoffice/backoffice-dashboard-sec/backoffice-dashboard-sec.vue"
      ),
    meta: {
      forMobile: false
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isUserAdmin"] || store.getters["user/isUserSec"])
        next();
      else next(false);
    }
  },
  {
    path: "backoffice-room",
    name: "backoffice-room",
    component: () =>
      import("@/views/backoffice/backoffice-room/backoffice-room.vue"),
    meta: {
      forMobile: false
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isUserAdmin"]) next();
      else next(false);
    }
  }
];
